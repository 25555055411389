import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import logo from "../assets/img/logo.svg"

import NavBarLinks from "./Links"
import styled from "styled-components"

const Hamburger = styled.div`
  background-color: #0085d7;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #0085d7;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Links = styled.div`
  display: grid;
  grid-column: span 8;

  @media (max-width: 768px) {
    display: none;
  }
`

const Toggle = styled.div`
  display: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: start;

  @media (max-width: 768px) {
    display: grid;
    grid-column: 12;
    grid-row: 1;
    align-self: center;
  }
`
const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 81px;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Header = ({ siteTitle }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <header
      style={{
        background: `white`,
        marginBottom: `1.45rem`,
      }}
    >
      <div className="header">
        <div className="menu">
          <div className="wrapper">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>

            {navbarOpen ? (
              <Navbox onClick={() => setNavbarOpen(false)}>
                <NavBarLinks />
              </Navbox>
            ) : (
              <Navbox open>
                <NavBarLinks />
              </Navbox>
            )}

            <Toggle onClick={() => setNavbarOpen(!navbarOpen)}>
              {navbarOpen ? <Hamburger open /> : <Hamburger />}
            </Toggle>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
